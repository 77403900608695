// import axios from "axios";

const version = "v1";
export function updateUser(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/users`,
    data,
  };
  if (data.id) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/users/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updatePointSetting(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}
// export function updateStores(merchantId, data) {
//   var config = {
//     method: "post",
//     url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores`,
//     data,
//   };
//   return window.qcsso.request(config);
// }
export function updatePointPeriod(merchantId, pointId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/periods`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/point-settings/${pointId}/periods/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateAnnualBudget(merchantId, organizationId, pointId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations/${organizationId}/point-settings/${pointId}/annual-budgets`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations/${organizationId}/annual-budgets/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateDepartment(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations/${data.organizationId}/departments`,
    data,
  };
  if (data.id) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateDepartmentBudget(
  merchantId,
  departmentId,
  pointId,
  data
) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/point-settings/${pointId}/department-budgets`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/department-budgets/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateCampaignBudget(merchantId, departmentId, pointId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/point-settings/${pointId}/campaign-budgets`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/campaign-budgets/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function approveAnnualBudget(
  merchantId,
  organizationId,
  budgetId,
  data
) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations/${organizationId}/annual-budgets/${budgetId}/approve`,
    data,
  };

  return window.qcsso.request(config);
}

export function approveDepartmentBudget(
  merchantId,
  departmentId,
  budgetId,
  data
) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/department-budgets/${budgetId}/approve`,
    data,
  };

  return window.qcsso.request(config);
}
export function approveCampaignBudget(
  merchantId,
  departmentId,
  budgetId,
  data
) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/campaign-budgets/${budgetId}/approve`,
    data,
  };

  return window.qcsso.request(config);
}

export function approveStore(merchantId, storeId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores/${storeId}/approve`,
    data,
  };

  return window.qcsso.request(config);
}

export function updateStoreCategory(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/store-categories`,
    data,
  };

  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/store-categories/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function returnCampaignBudget(merchantId, departmentId, budgetId, data) {
  // console.log("returnCampaignBudget:" + JSON.stringify(data));
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/campaign-budgets/${budgetId}/return`,
    data,
  };
  return window.qcsso.request(config);
}

export function approveWithdrawal(merchantId, withdrawRequestId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/withdrawals/${withdrawRequestId}/approve`,
    data,
  };

  return window.qcsso.request(config);
}

export function generateInviteUrl(merchantId, storeId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores/${storeId}/issue-invitation-url`,
    data,
  };

  return window.qcsso.request(config);
}

export function returnDepartmentBudget(
  merchantId,
  departmentId,
  budgetId,
  data
) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/departments/${departmentId}/department-budgets/${budgetId}/return`,
    data,
  };
  return window.qcsso.request(config);
}

export function uploadImage(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/uploadImage`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  };
  return window.qcsso.request(config);
}

export function updateBanner(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function approveBanner(merchantId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners/${data.id}`,
    data,
  };

  return window.qcsso.request(config);
}

export function updateArticle(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles`,
    data,
  };
  if (data.id) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function approveArticle(merchantId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles/${data.id}`,
    data,
  };

  return window.qcsso.request(config);
}
export function updateArticleCategory(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-categories`,
    data,
  };
  if (data.categoryId) {
    config = {
      method: "post",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-categories/${data.categoryId}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateOrganization(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations`,
    data,
  };
  if (data.organizationId) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/organizations/${data.organizationId}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateArticleSubCategory(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-subcategories`,
    data,
  };
  if (data.categoryId) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/article-subcategories/${data.categoryId}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function activeStore(merchantId, storeId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores/${storeId}/active`,
    data,
  };

  return window.qcsso.request(config);
}

export function deactiveStore(merchantId, storeId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores/${storeId}/deactive`,
    data,
  };

  return window.qcsso.request(config);
}

export function activeArticle(merchantId, articleId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles/${articleId}/active`,
    data,
  };

  return window.qcsso.request(config);
}

export function deactiveArticle(merchantId, articleId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/articles/${articleId}/deactive`,
    data,
  };

  return window.qcsso.request(config);
}

export function activeBanner(merchantId, bannerId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners/${bannerId}/active`,
    data,
  };

  return window.qcsso.request(config);
}

export function deactiveBanner(merchantId, bannerId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/banners/${bannerId}/deactive`,
    data,
  };

  return window.qcsso.request(config);
}

export function updateMemberInfo(merchantId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members/${data.memberId}`,
    data,
  };
  return window.qcsso.request(config);
}

export function refreshMemberInfo(merchantId, memberId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members/${memberId}/refresh`,
    data,
  };
  return window.qcsso.request(config);
}

export function sendStoreOpenEmail(merchantId, storeId, data) {
  var config = {
    method: "POST",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/stores/${storeId}/open-email`,
    data,
  };
  return window.qcsso.request(config);
}

export function updateDonateInvoiceCampaign(merchantId, data) {
  var config = {
    method: "POST",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/donate-invoices/campaign`,
    data,
  };
  return window.qcsso.request(config);
}

export function sendMessageTo(merchantId, memberId, data) {
  var config = {
    method: "POST",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/members/${memberId}/message`,
    data,
  };
  return window.qcsso.request(config);
}

export function refundPrememberPoint(merchantId, completeId, data) {
  var config = {
    method: "POST",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/merchants/${merchantId}/pre-members/${completeId}/refund`,
    data,
  };
  return window.qcsso.request(config);
}

export function executeQueryPreset(presetId, data) {
  var config = {
    method: "POST",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/query-presets/${presetId}/execute`,
    data,
  };
  return window.qcsso.request(config);
}

export function exportQueryPreset(presetId, data) {
  var config = {
    method: "POST",
    responseType: "blob",
    url: `${process.env.VUE_APP_APIHOST}/apis/${version}/query-presets/${presetId}/export`,
    data,
  };
  return window.qcsso.request(config);
}
